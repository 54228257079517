import axios from "axios";
import Cookies from "js-cookie";

const localhost = 'http://localhost:3001/'
const api = 'https://hdhr-apps-api.herokuapp.com/'

export const API = api;
export const SaveDocument = (object)=>{
    console.log(object)
    if(Cookies.get('user')){

    
const email = Cookies.get('user')
   let  resp = axios.post(API+'resume-checker/save-resume-result', {email:email, object});

if(resp === "error"){
    window.alert('Something went wrong. Please try again.')
} else {
    window.alert('Saved!')
}
    } else {
        window.alert('Please Login.')
    }

}


export const UpdateDocument = (object)=>{
    console.log(object)
    if(Cookies.get('user')){

    
const email = Cookies.get('user')
   let  resp = axios.post(API+'update-form-policy', {email:email, object});

if(resp === "error"){
    window.alert('Something went wrong. Please try again.')
} else {
    window.alert('Saved Changes!')
}
    } else {
        window.alert('Please Login.')
    }

}