import React, { useState } from 'react';
import { API } from '../classes/config/controller';
import { Form } from 'react-bootstrap';
import axios from 'axios';
function FileUploader(props) {
  const [text, setText] = useState('');

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
console.log(selectedFile.name.toLowerCase().includes('docx'))
if(selectedFile.name.toLowerCase().includes('docx')){
  
    if (selectedFile) {
      const formData = new FormData();
      formData.append('docx', selectedFile);

      try {
        const response = await fetch(API + 'resume-checker/convert-to-text', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const extractedText = await response.text();
          props.SetText(extractedText);
          console.log(extractedText)
        } else {
          console.error('Error converting DOCX to text:', response.status);
        }
      } catch (error) {
        console.error('Error converting DOCX to text:', error);
      }
      
    }


} else {

  if(selectedFile.name.toLowerCase().includes('pdf')){
  
    if (selectedFile) {
  try {
    const formData = new FormData();
    formData.append("file", selectedFile);

    const response = await axios.post(API + "resume-checker/pdf-to-text", formData);
    const textContent = response.data;

    // Do something with the extracted text content
    props.SetText(textContent);
          console.log(textContent)
  } catch (error) {
    console.error("Error uploading PDF:", error);
  }
};
}
}
  };

  return (
    <div>
      <Form.Control accept=".pdf,.docx" type="file" onChange={handleFileChange} />
    
      
    </div>
  );
}

export default FileUploader;
