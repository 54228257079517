
import React, {useState, useEffect} from "react";
import { Form } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { Box, Button, Paper, Typography, Fade } from "@mui/material";
import { Circle } from "../components/Circle";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LoadingModal from "../components/LoadingModal";
import { API, SaveDocument } from "../classes/config/controller";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";
import '../assets/css/style.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import DownloadDocxButton from "../components/DownloadDocx";
import FileUploader from "../components/FileUploader";



export const Writer = (props)=>{
const [editorContent, setEditorContent] = useState('');
const [loading, setLoading] = useState(true)
const [documentType, setDocumentType] = useState('')
const [documentTitle, setDocumentTitle] = useState('')
const [openModal, setOpenModal] = useState(false);
const [showDelete, setShowDelete] = useState(false);
const [data, setData] = useState([])
const [user, setUser] = useState(Cookies.get('user'))
const [update, setUpdate] = useState(false)
const [job, setJob] = useState(false)
const [jobTitle, setJobTitle] = useState('')
const [jobPost, setJobPost] = useState()
const [text, setText] = useState('');

const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

  
  
const handleBack = ()=>{
const check = window.confirm('If you choose to go back your job post will not be saved. Are you sure you would like to do this?')
if(check){
    
setLoading(true);
setDocumentType('');

}
}

const setupJobPost = (e)=>{
    let value;
    console.log(value)
    if(e.target.value === 'ANJ'){

        setJob(e.target.value)
    } else {
       value = e.target.value;

       console.log(value)
       

   
        setJobPost(value)
    }
    

 
}

useEffect(()=>{

    const getData = async ()=>{
            const resp = await axios.post(API+ 'get-data',{email:user})

            if(resp === 'error'){
                window.alert('Something went wrong when loading user data. Please try again.')
            } else {
                console.log(resp.data)
               
                setData(resp.data[0].jobPostings)
             
                // setLoading(false)
            }
    }
    getData()
},[update])
const [response, setResponse] = useState('');

const compareResume = ()=>{

    setOpenModal(true)
         const getData = async ()=>{
             console.log(jobPost)
             console.log(text)
             let prompt;
             if(job === 'ANJ'){
                 prompt = `Act as a Human Resources expert.As a person who is in charge of hiring for a company, please compare the resume below with the job title and rank the candidate on a scale of 1-10 as to if they are a fit for the position. Also give some context as to why the person may or may not be a good fit. Be honest, if the the job title and the resume have nothing to do with each other rank them appropriately. Pay close attention to the job experience to make sure that the candidate is compatible. Then In a nicely laid out table provide a list of pros and cons to hiring this candidate for this particular position.
 
                 Please return the results in nicely formatted html that can be displayed on a web page. Please return the html page in this sample layout:
 
                 <p style="font-size:18px"><b>Job Title:</b><span id='jobTitle'>(Job Title from Job Post)</span></p>
                 
                 <p style="font-size:18px"><b>Candidate Name:</b> id='candidateName'(name from ‘Resume’)</span></h4>
                 
                 <p style="font-size:18px"><b>Ranking:</b> <span id='score'>ranking/10</span></p>
                 
                 <p>Explanation of why this person is a fit paragraph</p>
                
                 <p style="font-size:18px"><b>PROS</b></p>
                 
                 <ul>(4 to 5 list items on the the pros of hiring this candidate with information that only pertains to this particular resume. Please ONLY list college expirience if it is listed on the resume.)</ul>
 
                 <p style="font-size:18px"><b>CONS</b></p>
                 
                 <ul>(4 to 5 list items on the cons of hiring this candidate with information that only pertains to this particular resume.)</ul>
 
 
                 
                 
                 Resume: ${text}
                 
                 
                 Job Title: ${jobTitle}
                 
                 Please rank on a scale from 1 - 10 how much of a fit this resume is for this job title and give reasons as to why it is or isn't a good fit.
                 
                 Return the results in nicely formatted html.
                 `
 
                 
 
             } else {
                 prompt = `As a person who is in charge of hiring for a company, please compare the resume below with the job post and rank the candidate on a scale of 1-10 as to if they are a fit for the position. Also give some context as to why the person may or may not be a good fit. Be honest, if the the job post and the resume have nothing to do with each other rank them appropriately. Pay close attention to the job experience to make sure that the candidate is compatible. Then In a nicely laid out table provide a list of pros and cons to hiring this candidate for this particular position. 
                 
                 Please return the results in nicely formatted html that can be displayed on a web page. Please return the html page in this sample layout:
 
                 <p style="font-size:18px"><b>Job Title:</b><span id='jobTitle'>(Job Title from Job Post)</span></p>
                 
                 <p style="font-size:18px"><b>Candidate Name:</b><span id='candidateName'>(name from ‘Resume’)</span></h4>
                 
                 <p style="font-size:18px"><b>Ranking:</b><span id='score'>ranking/10</span></p>
                 
                 <p>Explanation of why this person is a fit paragraph</p>
                 
                 <p style="font-size:18px"><b>PROS</b></p>
                 
                 <ul>(4 to 5 list items on the the pros of hiring this candidate with information that only pertains to this particular resume.  Please ONLY list college expirience if it is listed on the resume.)</ul>
 
                 <p style="font-size:18px"><b>CONS</b></p>
                 
                 <ul>(4 to 5 list items on the cons of hiring this candidate with information that only pertains to this particular resume.)</ul>
 
 
                 
                 Resume: ${text}
                 
                 
                 Job Post: ${jobPost}
                 
                
                 `            }
            
                     const response = await axios.post(API+'api/create-document',{request:prompt})
                  if(response != 'error'){
                     console.log(response.data)
             
                   
                     try {
                         const result = response.data;
                       
                      
                            setEditorContent(result)
                  
                        
                     
                         setOpenModal(false)
                         setLoading(false)
                     } catch (error) {
                         setOpenModal(false)
                     console.error('Error parsing JSON response:', error);
                     alert('Something went wrong please try again.')
                     return;
                     }
                   
                 //     let arr = JSON.parse(response.text)
 
                    
             
                   
 
                  } else{
                     alert('Something went wrong please try again.')
                  }
                 }
 
 
 
                 if(job !== 'ANJ' && text === ''){
                     alert('Please make sure you have uploaded a resume.');
                     setOpenModal(false)
                 } else {
 
                     if(job === 'ANJ' && jobTitle === ''){
 
                         alert('Please make sure you have added a job title that will be review with the resume.');
 
                         setOpenModal(false)
                     } else {
 
                         getData()
                     }
                   
                 }
                 
                
                
              
    
    
}
if(loading) {
    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

        <div style={{padding:'20px'}}>

            <Box>

          
         <Container>
         <div>
         <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="1" Color="#FD7400"/>&nbsp; &nbsp; Upload Resume</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Please upload the resume.(Docx or PDF)</Typography>
         <br></br>
         <Row xs={1} md={2}>
    
         <FileUploader
         SetText={setText}
         Text={text}
         />
    
      </Row>
      </div>
      <div className="mt-5">
      <Typography  variant="button" sx={{fontSize:'16px', marginTop:5}}><b><Circle Title="2" Color="#FD7400"/>&nbsp; &nbsp; Select Job</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">You can select a job from a job post you have created in the Job Post Genie or add a new title by selecting ADD NEW JOB</Typography>
            <Row className="mt-3" xs={1} md={2}>
    
   <Form.Select 
   defaultValue={'---'}
   onChange={(e)=>{setupJobPost(e); setJob(e.target.value)}}
   >
    <option>---</option>
    <option value='ANJ'>ADD NEW JOB</option>
    <optgroup label="Job Post Genie Posts">
    {data.map(i=>{
        return <option value={i.content}>{i.jobName}</option>
    })}
    </optgroup>
   </Form.Select>

 </Row>
 </div>
 <Fade  in={job === 'ANJ' ? 'block': 'none'}>
 <div style={{display: job === 'ANJ' ? 'block': 'none'}} className="mt-5">
 

         <Typography variant="button" sx={{fontSize:'16px'}}><b><Circle Title="3" Color="#FD7400"/>&nbsp; &nbsp; JOB TITLE</b></Typography><br/>
            <Typography sx={{marginTop:'10px'}} variant="subtitle2">Enter in the Job Title you would like to compare this resume with. <b>NOTE:</b> While this feature will work, you may not get as detailed of results as if you were to compare the Resume to a <b>Job Post</b> created with our <a target="_blank" title="Open Job Post Genie in new page" href="https://jpg.helpdeskforhr.com"><b>Job Post Genie</b></a></Typography>
         <br></br>
         <Row xs={1} md={2}>
    
        <Form.Control value={jobTitle} onChange={(e)=>{setJobTitle(e.target.value)}} />
    
      </Row>
 
      </div>
      </Fade> 
 
      </Container>
           


            </Box>
            
            <br></br>
            <div style={{textAlign:'right', display:documentType !== '---'?'block': 'none'}}>
            <Button sx={{marginTop:1, backgroundColor:'#0172C0'}} variant="contained" onClick={compareResume}><b>Compare</b></Button>
            </div> 
          <LoadingModal
          Open={openModal}
         
          />
       
        </div>
        </Paper>
    )
} else {
    
    return (
        <div>

            <Box sx={{marginBottom:'10px'}}>
            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>            
            <DownloadDocxButton htmlContent={editorContent} docName={documentTitle} />
            <Button onClick={()=>{
                 let object = {
                    id:uuidv4(),
                    dateCreated: new Date(),
                    jobName:document.getElementById('jobTitle').innerText,
                    candidateName: document.getElementById('candidateName').innerText,
                    score: document.getElementById('score').innerText,
                    content: editorContent 
                }

                console.log(object)
                SaveDocument(object);
                props.Action()
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE</b></Button>
            </Box>
        <Paper  sx={{ p: 5, display: 'flex', flexDirection: 'column' }}>
        <div dangerouslySetInnerHTML={{__html:editorContent}}>
     
    
    </div>
    </Paper>
    </div>
)

}
}